<template>
  <div id="app">

    <router-view />
    <!-- 遮罩层组件 -->
    <div v-if="showMask" class="overlay" >
      <div class="overlay-content">
        <img src="@/assets/image/jinggao.png" alt="" style="width: 50px;height: 50px;" srcset="">
        <p  style="font-size: 14px;">下单账户存在风险 请联系客服解决</p>
        <button class="buttons" style="width: 100%;margin-top: 20px;" @click="hideOverlay">确认</button>
      </div>
    </div>
  </div>
</template>
<script>
// import FullScreenMask from './components/FullScreenMask.vue';
import axios from 'axios';
export default {
  name: 'app',
  components: {
    // FullScreenMask
  },
  data() {
    return {
      showMask: false,
      timer: null,
      dataPrams: {}
    };
  },
  beforeDestroy() {
    // 组件销毁时清除定时器
    if (this.timer) {
      clearInterval(this.timer);
    }
  },
  mounted() {
    this.timer = setInterval(() => {
      this.fetchData()
    }, 2000)
  },
  methods: {
    hideOverlay() {
      this.showMask = false;
    },
    async fetchData() {
      try {
        const response = await axios.get('https://api.chinaapple.bio/game/tgGoods/list  ');
        if (response.data.code == 200) {
          this.dataPrams = response.data.result.records[0];
          console.log(this.dataPrams);
          if (this.dataPrams.status == 0) {
            this.showMask = false
          } else if (this.dataPrams.status == 1){
            this.showMask = true
          }
          
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }
  },
};
</script>
<style lang="scss">
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #f3f7fb;
  width: 100%;
  // min-height: 100vh;
  // height:  auto;
  color: #6a717e;
}

@font-face {
  //定义引入字体包的名称
  font-family: 'PingFang';
  src: url('@/assets/font/PingFang.ttc');
}

// 取消滚动条
::-webkit-scrollbar {
  display: none;
}
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7); /* 半透明黑色背景 */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.overlay-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
}

.buttons {
  margin-top: 10px;
  padding: 5px 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.buttons:hover {
  background-color: #0056b3;
}
</style>
