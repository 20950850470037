<template>
  <div class="header_container">
    <img  :src="require('@/assets/header/logo.png')" alt="" srcset="" class="header_logo">
    <div class="rightItem">
      <img  :src="require('@/assets/header/serch.png')" alt="" srcset="" style="margin-right: 20px;" class="header_logo2">
    <img  :src="require('@/assets/header/gouwu.png')" alt="" srcset="" style="margin-right: 20px;" class="header_logo2">

    <img class="header_logo" @click="openMobileMenu" :src="require('@/assets/header/mobile_sun_icon.png')" alt=""
         srcset="">
    <div class="mobile_menu_modal" :style="{ right: mobileMenuShow ? 0 : '-100vw' }">
      <ul class="mobile_menu">
        <div class="close_modal" @click="mobileMenuShow = false">
          <img :src="require('@/assets/header/close.png')" alt="" srcset="">
        </div>
        <li :class="activeIndex === item.path ? 'active' : ''" v-for="(item, index) in menuList" :key="index"
            @click="changeActive(item)">
          {{ item.name }}
        </li>
      </ul>
    </div>
    </div>

  </div>
</template>

<script>
export default {
  name: 'LayoutHeader',
  data () {
    return {
      activeIndex: 0,
      menuList: [
        {
          name: '网站首页',
          path: 'Home'
        },
        {
          name: '订单',
          path: 'order'
        },
        {
          name: '订单',
          path: 'inputtest'
        },
        {
          name: '支付方式',
          path: 'payment'
        },
        
       
      ],
      mobileMenuShow: false
    }
  },
  mounted () {
    window.addEventListener('resize', this.handleResize)
    this.activeIndex = this.$route.name || 'Home'
  },
  methods: {
    goHome () {
      this.$router.push({ name: 'Home' })
    },
    btnClick () {
      // window.open('https://t.wave360.club?p=e09830077b16e8ce97d60f4e46d9acbd')
    },
    handleResize () {
      if (this.mobileMenuShow && window.innerWidth > 750) {
        this.mobileMenuShow = false
      }
    },
    changeActive (item) {
      if (this.$route.name === item.path) return
      this.activeIndex = item.path
      this.mobileMenuShow = false
      this.$router.push({ name: item.path })
    },
    openMobileMenu () {
      // this.mobileMenuShow = !this.mobileMenuShow
    }
  }
}
</script>

<style lang="scss" scoped>
.header_container {
  width: 100%;
  height: 99px;
  padding: 25px 290px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .header_logo {
    width: 50px;
    height: 50px;
  }
  .header_logo2{
      width: 50px;
      height: 50px;
    }
  ul {
    li {
      color: #243042;
      white-space: nowrap;
      list-style: none;
      font-size: 16px;
      cursor: pointer;
      &.active {
        font-weight: bold;
        color: #2c9dff;
      }
    }

  }

  .pc_menu {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 49px;

    li {
      position: relative;
      transition: all .2s;
      margin-left: 56px;

      .active_arrow {
        position: absolute;
        bottom: -22px;
        left: 50%;
        transform: translateX(-50%);
        width: 18px;
        height: 18px;
        display: none;
      }

      .tg_icon {
        width: 34px;
        height: 34px;
      }

      &.tg_ {
        margin-left: 38px;
        width:110px;
        height:28px;
        background:rgba(255, 255, 255, 0.1);
        border: 1px solid #2c9dff;
        border-radius:30px;
        backdrop-filter:blur(5px);
        color:#2c9dff;
        font-size:14px;
        line-height: 28px;
        img {
          margin-top: -2px;
          margin-right: 4px;
          width: 28px;
          height: 28px;
          font-size: 0;
          vertical-align: middle;
        }
      }

      &.active {
        .active_arrow {
          display: block;
        }
      }
    }

  }
}

.mobile_menu_modal {
  position: fixed;
  top: 0;
  right: -100vw;
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, .8);
  z-index: 999;
  transition: right .3s;

  .close_modal {
    display: inline-block;
    margin: 36px 0 39px;
    width: 90px;
    height: 40px;
    border: 1px solid #eeeeee;
    border-radius: 24px;
    line-height: 40px;
    text-align: center;

    img {
      width: 14px;
      height: 14px;
      font-size: 0;
    }
  }
}

.mobile_menu_sub {
  display: none;
}

.mobile_menu {
  position: absolute;
  top: 0;
  right: 0;
  height: 100vh;
  width: 74vw;
  text-align: center;
  background-color: rgba(255, 255, 255, .96);
  //backdrop-filter: blur(10px);
  li {
    height: 60px;
    line-height: 60px;
    border-bottom: 1px solid rgba(36, 48, 66, .1);
  }
}

@media screen and (max-width: 750px) {
  .header_container {
    padding: 15px;
    height: 65px;

    .header_logo {
      width: 25px;
      height: 25px;
    }
    .header_logo2{
      width: 25px;
      height: 25px;
    }
    .rightItem{
      display: flex;
      ._logo {
      width: 34px;
      height: 34px;
    }
    .pc_menu {
      display: none;
    }

    .mobile_menu_sub {
      width: 20px;
      height: 20px;
      display: block;
    }

    }

  }
}
</style>
