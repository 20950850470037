import Vue from 'vue'
import VueRouter from 'vue-router'

import Layout from '@/layout'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Layout,
    redirect: '/home',
    children: [
      {
        path: 'home',
        name: 'Home',
        component: () => import('@/views/index/index.vue')
      }
    ]
  },
  {
    path: '/order',
    name: 'order',
    component: Layout,
    redirect: '/order',
    children: [
      {
        path: 'index',
        name: 'order',
        component: () => import('@/views/order/index.vue')
      }
    ]
  },

  {
    path: '/payment',
    name: 'payment',
    component: Layout,
    redirect: '/payment',
    children: [
      {
        path: 'index',
        name: 'payment',
        component: () => import('@/views/payment/index.vue')
      }
    ]
  },
  

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
