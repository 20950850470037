<template>
  <div>
    <div class="header-c">
      <Hheader class="header"></Hheader>
    </div>
    <div>
      <router-view></router-view>
    </div>
    <Hfooter></Hfooter>
  </div>
</template>

<script>
import Hheader from './component/header.vue'
import Hfooter from './component/footer.vue'
export default {
  name: 'LayoutIndex',
  components: {
    Hheader,
    Hfooter
  }
}
</script>

<style lang="scss" scoped>
.header-c {
  position: relative;
  height: 99px;
}
@media screen and (max-width: 750px) {
  .header-c {
    height: 64px;
  }
}
.header {
  position: fixed;
  background:#f3f7fb;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;
}
</style>
